import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OnesignalService } from './servicios/onesignal/onesignal.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { FirebaseAnalyticsService } from './servicios/firebase-analytics/firebase-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';
import { StorageService } from './servicios/storage/storage.service'; 

register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: false,
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private onesignalService: OnesignalService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private translate: TranslateService,
    private storageService: StorageService 
  ) {
    this.platform.ready().then(async () => {
      /* OneSignal */
      this.onesignalService.init();

      await this.applyDarkMode();

      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);
    });

    let lang = window.navigator.language;
    if (lang.indexOf('es') >= 0) {
      this.translate.setDefaultLang('es');
    } else if (lang.indexOf('pt') >= 0) {
      this.translate.setDefaultLang('pt');
    } else if (lang.indexOf('en') >= 0) {
      this.translate.setDefaultLang('en');
    } else {
      this.translate.setDefaultLang('es');
    }
  }

  async applyDarkMode() {
    const savedDarkMode = await this.storageService.get('modo_dark');
    const isDarkMode = savedDarkMode !== null ? savedDarkMode : false;
    document.body.classList.toggle('dark', isDarkMode);
  }
}

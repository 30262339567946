// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //api_url: "https://qa.soty.dev/api/app/v1/",
  //api_url: "http://soty.local:89/api/app/v1/",
  //api_url: "http://localhost:88/api/app/v1/",
  api_url_api_spin: "http://192.168.1.11:3000/",
  //api_url_socket: "http://0.0.0.0:3001/",
  api_url_socket: "https://spin.soty.dev/",
  //api_url: "http://soty.local/api/app/v1/",
  //api_url: "http://localhost:89/api/app/v1/",
  //api_url: "http://localhost/api/app/v1/",
  api_url: "https://preprod.soty.dev/api/app/v1/",
  //api_url: "https://api.sotysolar.es/api/app/v1/",
  domain_url: "https://preprod.soty.dev/",
  name_app: "Clientes",
  app_name_slug: "clientesapp",
  version_android: "1.1.21",
  version_codeAndroid: 118,
  version_ios: '1.1.21',
  version_code_ios: 118,
  usu_storage: 'usu-cli-soty',
  cli_storage: 'cli-cli-soty',
  telefonocontacto: '34693905757',
  sotyS3Publico: 'https://soty.ams3.digitaloceanspaces.com/publico/',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

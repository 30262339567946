import { Directive, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appScrollHeader]',
  standalone: false,
})
export class ScrollHeaderDirective {
  private lastScrollTop = 0; // Última posición del scroll
  private threshold = 10; // Sensibilidad para detectar el movimiento del scroll
  private header: any;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    // Encuentra el header más cercano al contenido
    this.header = document.querySelectorAll('app-nav-header');
  }

  @HostListener('ionScroll', ['$event'])
  onContentScroll(event: any) {
    const currentScrollTop = event.detail.scrollTop;
    if (!this.header) {
      return;
    }

    // Detecta si el scroll se mueve hacia abajo o hacia arriba
    if (currentScrollTop > this.lastScrollTop + this.threshold) {
      // Scroll hacia abajo -> Oculta el header
      this.header.forEach((header) => { 
        this.renderer.addClass(header, 'header-hidden');
      });
    } else if (currentScrollTop < this.lastScrollTop - this.threshold) {
      // Scroll hacia arriba -> Muestra el header
      this.header.forEach((header) => { 
        this.renderer.removeClass(header, 'header-hidden');
      });
    }

    this.lastScrollTop = currentScrollTop;
  }

}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImgErrorDirective } from './img-error/img-error.directive';
import { SelectPopoverDirective } from './select-popover/select-popover.directive';
import { SoloNumerosDirective } from './solo-numeros/solo-numeros.directive';
//import { DatetimeTextDirective } from './datetime-text/datetime-text.directive';
//import { SwipeDirective } from './swipe/swipe.directive';



@NgModule({
  declarations: [
    ImgErrorDirective,
    //DatetimeTextDirective,
    SoloNumerosDirective,
    SelectPopoverDirective,
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    ImgErrorDirective,
    //DatetimeTextDirective,
    SoloNumerosDirective,
    SelectPopoverDirective,
  ]
})
export class CommonDirectivesModule { }

import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-video-ayuda',
  templateUrl: './modal-video-ayuda.component.html',
  styleUrls: ['./modal-video-ayuda.component.scss'],
  standalone: false,
})
export class ModalVideoAyudaComponent implements OnInit {

  @Input() url: string;
  public urlSecurity;
  constructor(
    private sanitizer: DomSanitizer,
    private modalController: ModalController,
  ) { }

  ngOnInit() {
    this.urlSecurity = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

  onCloseModal() {
    this.modalController.dismiss();
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import lottie from 'lottie-web';

@Component({
  selector: 'app-modal-timeline-pasos',
  templateUrl: './modal-timeline-pasos.component.html',
  styleUrls: ['./modal-timeline-pasos.component.scss'],
  standalone: false,
})
export class ModalTimelinePasosComponent implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() { }

  ngAfterViewInit() {
    let elemento: any = document.getElementById('lottie-container-timeline');
    lottie.loadAnimation({
      container: elemento,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/animaciones/timeline/data.json'
    });
  }
  
  animationCreated(animationItem: AnimationItem): void {
    //console.log(animationItem);
  }

  onCloseModal() {
    this.modalController.dismiss();
  }

}

<ion-content class="ion-margin-top">
  <div class="ion-padding">
    <h1><b>¿Por qué en una gráfica vemos potencia y en otra energía? </b></h1>
    <ion-label>La <b>potencia (kW)</b> y la <b>energ&iacute;a (kWh)</b> son valores
        distintos.<br><br><b>La energ&iacute;a (kWh)</b> nos sirve para calcular
        nuestro ahorro. En la factura de la luz siempre veremos reflejado este
        valor como ahorro.<br><br>Adem&aacute;s, tambi&eacute;n los excedentes
        ser&aacute;n calculados en funci&oacute;n del n&uacute;mero de <b>kWh</b>
        exportados a la red.<br><br><b>&iquest;Por qu&eacute; analizamos la
        potencia entonces?</b><br><br>Los valores de energ&iacute;a en tramos
        cortos, de 5-10 minutos, son valores muy peque&ntilde;os. Teniendo en
        cuenta que la media de energ&iacute;a consumida diariamente en un hogar
        es de 10 kWh, su representaci&oacute;n en una gr&aacute;fica se
        acercar&aacute; mucho a cero.<br><br>En cambio la <b>potencia (kW)</b> es un
        valor instant&aacute;neo y siempre ser&aacute; mayor que el de la
        energ&iacute;a. Su visi&oacute;n en una gr&aacute;fica es mucho
        m&aacute;s representativa.<br><br><b>Potencia actual kW</b><br>Refleja la
        potencia que ha necesitado la vivienda en cada instante.<br><br>La
        potencia m&aacute;xima se alcanza en momentos de perfecta
        orientaci&oacute;n, inclinaci&oacute;n y ausencia de sombras. La
        suciedad y el calor son factores que afectan negativamente al alcance de
        la potencia m&aacute;xima.<br><br><b>Energ&iacute;a generada kWh</b><br>Refleja
        la energ&iacute;a diaria/mensual/anual de tu instalaci&oacute;n
        fotovoltaica.<br>Se observan datos de energ&iacute;a exportada,
        importada y consumida con los que podremos hacer los c&aacute;lculos de
        ahorro en la factura.</ion-label>
    <ion-button (click)="onCerrar()">Entiendo</ion-button>
  </div>
</ion-content>

import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { AutenticacionService } from 'src/app/servicios/autenticacion/autenticacion.service';
import { CallNumberService } from 'src/app/servicios/call-number/call-number.service';
import { NavegacionService } from 'src/app/servicios/navegacion/navegacion.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  standalone: false,
})
export class NavMenuComponent implements OnInit{

  public env = environment;
  darkMode = false;

  constructor(
    private navegacionService: NavegacionService,
    private autenticacionService: AutenticacionService,
    public platform: Platform,
    public callNumberService: CallNumberService,

  ) {}

  ngOnInit() { 
  }

  onIr(url) {
    this.navegacionService.goTo(url);
  }
  
  logout() {
    this.autenticacionService.logout();
  }

  onCall() {
    window.open('tel:+'+environment.telefonocontacto);
  }

  onSendMail() {
    window.open("mailto:clientes@sotysolar.es");
  }

}

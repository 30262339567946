import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-monitorizacion',
  templateUrl: './modal-monitorizacion.component.html',
  styleUrls: ['./modal-monitorizacion.component.scss'],
  standalone: false,
})
export class ModalMonitorizacionComponent implements OnInit {

  constructor(
    private modalController: ModalController,
  ) { }

  ngOnInit() {
  }

  onCerrar() {
    this.modalController.dismiss();
  }
}

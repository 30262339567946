<ion-content>
  <div class="sub-button">
    <ion-button fill="clear" color="primary" class="close-modal" (click)="onCloseModal()">
      <ion-icon name="close-circle-outline"></ion-icon>
    </ion-button>
    <!-- <ion-buttons class="close-modal">
    </ion-buttons> -->
  </div>
  <div class="iframe">
    <iframe *ngIf="urlSecurity" width="100%" height="100%" [src]="urlSecurity" title="Suma Solar: un valor extra para tus paneles solares 🤝 💛 SotySolar" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</ion-content>
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { CommonDirectivesModule } from '../directivas/common-directives.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavMenuComponent } from './navegacion/nav-menu/nav-menu.component';
import { NavHeaderComponent } from './navegacion/nav-header/nav-header.component';
import { ModalInformativaComponent } from './modales/modal-informativa/modal-informativa.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormErrorsComponent } from './formularios/form-errors/form-errors.component';
import { FormAgregarImagenComponent } from './formularios/form-agregar-imagen/form-agregar-imagen.component';
import { ModalVideoAyudaComponent } from './modales/modal-video-ayuda/modal-video-ayuda.component';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';
import { ModalTimelinePasosComponent } from './modales/modal-timeline-pasos/modal-timeline-pasos.component';
import { ModalVerImagenComponent } from './modales/modal-ver-imagen/modal-ver-imagen.component';
import { ModalMonitorizacionComponent } from './modales/modal-monitorizacion/modal-monitorizacion.component';
import { ModalGuardoCorrectamenteComponent } from './modales/modal-guardo-correctamente/modal-guardo-correctamente.component';
import { ModalIncidenciaEnviadaComponent } from './modales/modal-incidencia-enviada/modal-incidencia-enviada.component';
import { NavHeaderContentComponent } from './navegacion/nav-header-content/nav-header-content.component';

export function playerFactory() {
  return player;
}

const components = [
  NavMenuComponent,
  NavHeaderComponent,
  NavHeaderContentComponent,

  ModalInformativaComponent,
  ModalVideoAyudaComponent,
  ModalTimelinePasosComponent,
  ModalVerImagenComponent,
  ModalMonitorizacionComponent,
  FormErrorsComponent,
  FormAgregarImagenComponent,
  ModalGuardoCorrectamenteComponent,
  ModalIncidenciaEnviadaComponent,
];

@NgModule({
  declarations: components,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    IonicModule,
    CommonModule,
    TranslateModule,
    CommonDirectivesModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule.forRoot({ player: playerFactory }),
  ],
  exports: components,
})
export class ComponentesCompartidosModule {}

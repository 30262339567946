import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavegacionService } from 'src/app/servicios/navegacion/navegacion.service';

@Component({
  selector: 'app-nav-header-content',
  templateUrl: './nav-header-content.component.html',
  styleUrls: ['./nav-header-content.component.scss'],
  standalone: false,
})
export class NavHeaderContentComponent  implements OnInit {

  @Input() titulo: string;
  @Input() volver: boolean;
  @Input() iconBtnDerecha: string;
  @Output() onBtnDerecha = new EventEmitter();
  

  constructor(
    private navegacionService: NavegacionService,
  ) { }

  ngOnInit() { }
  
  onVolver() {
    this.navegacionService.back();
  }

  btnDerecha() {
    this.onBtnDerecha.emit(event);
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-incidencia-enviada',
  templateUrl: './modal-incidencia-enviada.component.html',
  styleUrls: ['./modal-incidencia-enviada.component.scss'],
  standalone: false,
})
export class ModalIncidenciaEnviadaComponent implements OnInit {

  constructor(
    private modalContrller: ModalController,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.close()
    }, 2500);
  }

  close() {
    this.modalContrller.dismiss();
  }
}

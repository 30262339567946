<ion-content class="ion-padding">
  <ion-card class="ion-no-margin">
    <ion-button class="btn-cerrar" (click)="onCerrar()" expand="block" fill="clear" shape="round" size="small">
      <ion-icon name="close-circle"></ion-icon>
    </ion-button>
    <img src="https://picsum.photos/900/500" />
    <ion-card-header class="ion-no-padding">
      <ion-card-subtitle class="subtitulo1 ion-text-uppercase">{{"modal-informativa.txt-equipo-sotysolar" | translate}}</ion-card-subtitle>
      <ion-card-subtitle class="subtitulo2 ion-text-uppercase">Información general</ion-card-subtitle>
      <ion-card-title>Julián, tienes una incidencia!</ion-card-title>
    </ion-card-header>
    <ion-card-content class="ion-no-padding">
      <p>Contacta con el equipo técnico SotySolar para más información</p>
      <a href="#">O revisa el pedido P1008754</a>
    </ion-card-content>
  </ion-card>
</ion-content>

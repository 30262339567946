// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-size: 20px;
  font-weight: bold;
}
.header span {
  text-align: center;
  width: 100%;
}

.icon-vacio {
  width: 40px;
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/navegacion/nav-header-content/nav-header-content.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,aAAA;EACA,eAAA;EACA,iBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;AAER;;AACA;EACI,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".header{\n    display: flex;\n    width: 100%;\n    flex-direction: row;\n    align-items: center;\n    justify-content: space-between;\n    padding: 20px;\n    font-size: 20px;\n    font-weight: bold;\n    span{\n        text-align: center;\n        width: 100%;\n    }\n}\n.icon-vacio{\n    width: 40px;\n    height: 40px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

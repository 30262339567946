import { Injectable } from '@angular/core';
import { ActionSheetController, AlertController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AlertasService {

  public loading: any;
  timeoutLoading: any;
  timeoutUploadSuccess: any;

  constructor(
    private alertController: AlertController,
    private toastController: ToastController,
    private actionSheetController: ActionSheetController,
    public translate: TranslateService,
    ) { }

    async presentAlertConfirm(title, msg, callbackOk?, callbackCancel?) {
      if(!title) title = this.translate.instant("alertas-service.alert-confirm.title-confirm")
      const alert = await this.alertController.create({
        header: title,
        message: msg,
        buttons: [
          {
            text: this.translate.instant("alertas-service.alert-confirm.btn-cancel"),
            cssClass: 'secondary',
            handler: callbackCancel
          }, {
            text: this.translate.instant("alertas-service.alert-confirm.btn-accept"),
            handler: callbackOk
          }
        ]
      });
      await alert.present();
    }

  async presentAlertInfo(title, msg, callbackOk?) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: [{
        text: this.translate.instant("alertas-service.alert-confirm.btn-accept"),
        handler: callbackOk
      }]
    });

    await alert.present();
  }

  async presentAlertForm(title = "", msg = "", inputs = [], callbackOk?, callbackCancel?) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      inputs: inputs,
      buttons: [
        {
          text: this.translate.instant("alertas-service.alert-confirm.btn-cancel"),
          cssClass: 'secondary',
          handler: callbackCancel
        }, {
          text: this.translate.instant("alertas-service.alert-confirm.btn-accept"),
          handler: callbackOk
        }
      ]
    });
    await alert.present();
  }

  async presentAlertBtns(title, msg, buttons) {
    const alert = await this.alertController.create({
      header: title,
      message: msg,
      buttons: buttons
    });

    await alert.present();
  }

  async presentActionSheet(actionLinks, title = this.translate.instant("alertas-service.present-element.action-sheet.subtitle-select-option")) {
    const actionSheet = await this.actionSheetController.create({
      header: title,
      buttons: actionLinks,
      cssClass: "",
      mode: "ios",
    });
    await actionSheet.present();
  }

  async presentToast(msg, time = 2000) {
    const toast = await this.toastController.create({
      message: msg,
      duration: time,
      cssClass: "",
      mode: "md",
    });
    toast.present();
  }

  presentLoading(time = 15000, text = "") {
    document.querySelector('.custom-loading').classList.remove("hide");
    if (text) {
      (document.querySelector('.custom-loading p') as any).textContent = text;
    (document.querySelector('.custom-loading p') as any).style.backgroundColor = 'white';

    }
    this.timeoutLoading = setTimeout(() => { 
      this.closePresentLoading();
    },time);
  }

  closePresentLoading() {
    clearTimeout(this.timeoutLoading);
    document.querySelector('.custom-loading').classList.add("hide");
    (document.querySelector('.custom-loading p') as any).textContent = "";
    (document.querySelector('.custom-loading p') as any).style.backgroundColor = '';
    
  }

  presentUploadSuccess(timeClose = 15000) {
    document.querySelector('.custom-upload-success').classList.remove("hide");
    this.timeoutUploadSuccess = setTimeout(() => {
      this.closePresentUploadSuccess();
    },timeClose);
  }

  closePresentUploadSuccess() {
    clearTimeout(this.timeoutUploadSuccess);
    document.querySelector('.custom-upload-success').classList.add("hide");
  }

}

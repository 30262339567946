import { Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GeneralObsService {

  private clienteCreacion = new Subject<any>();
  private actualizaCliente = new Subject<any>();
  private irDetalleCliente = new Subject<any>();
  private refrescarClientes = new Subject<any>();
  private actualizacionDispSpin = new Subject<any>();
  private actualizacionPeriodo = new Subject<any>();
  private actualizacionEstadoSpin = new Subject<any>();
  private connectedSpin = new Subject<any>();
  
  constructor() { }

  clienteCreacionPub(params:  any = null) {
    this.clienteCreacion.next(params);
  }

  clienteCreacionObs() {
    return this.clienteCreacion;
  }

  actualizaClientePub(params: any = null) {
    this.actualizaCliente.next(params);
  }

  actualizaClienteObs() {
    return this.actualizaCliente;
  }

  irDetalleClientePub(params:  any = null) {
    this.irDetalleCliente.next(params);
  }

  irDetalleClienteObs() {
    return this.irDetalleCliente;
  }

  refrescarClientesPub(params: any = null) {
    this.refrescarClientes.next(params);
  }

  refrescarClientesObs() {
    return this.refrescarClientes;
  }

  actualizacionDispSpinPub(params:  any = null) {
    this.actualizacionDispSpin.next(params);
  }

  actualizacionDispSpinObs() {
    return this.actualizacionDispSpin;
  }
  
  actualizacionPeriodoPub(params: any = null) {
    this.actualizacionPeriodo.next(params);
  }

  actualizacionPeriodoObs() {
    return this.actualizacionPeriodo;
  }

  actualizacionEstadoSpinPub(params: any = null) {
    this.actualizacionEstadoSpin.next(params);
  }

  actualizacionEstadoSpinObs() {
    return this.actualizacionEstadoSpin;
  }
  
  connectedSpinPub(params: any = null) {
    this.connectedSpin.next(params);
  }

  connectedSpinObs() {
    return this.connectedSpin;
  }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import lottie from 'lottie-web';


@Component({
  selector: 'app-modal-guardo-correctamente',
  templateUrl: './modal-guardo-correctamente.component.html',
  styleUrls: ['./modal-guardo-correctamente.component.scss'],
  standalone: false,
})
export class ModalGuardoCorrectamenteComponent implements OnInit {

  constructor(
    private modalContrller: ModalController,
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.close()
    }, 2000);
  }

  ngAfterViewInit() {
    let elemento: any = document.getElementById('lottie-container-modal-guardo');
    lottie.loadAnimation({
      container: elemento,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: '/assets/animaciones/guardo-correctamente/data.json'
    });
  }

  close() {
    this.modalContrller.dismiss();
  }

}

<div class="header">
  <ion-button class="btn-icon" fill="clear" (click)="onVolver()">
    <ion-icon name="arrow-back"></ion-icon>
  </ion-button>
  <span class="ion-margin-horizontal">
    {{titulo}}
  </span>
  <ion-button [ngStyle]="{'opacity': (!iconBtnDerecha) ? '0': '1'}" class="btn-icon" fill="clear" (click)="iconBtnDerecha && btnDerecha()">
    <ion-icon [name]="iconBtnDerecha"></ion-icon>
  </ion-button>
</div>
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-form-errors',
  templateUrl: './form-errors.component.html',
  styleUrls: ['./form-errors.component.scss'],
  standalone: false,
})
export class FormErrorsComponent implements OnInit {

  @Input() validation_messages:any;
  @Input() formCtrlName:string;
  @Input() formCtrlValue:any;
  

  constructor() { }

  ngOnInit() {
  }

}

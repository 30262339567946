import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { OnesignalService } from './servicios/onesignal/onesignal.service';
import { SplashScreen } from '@capacitor/splash-screen';
import { FirebaseAnalyticsService } from './servicios/firebase-analytics/firebase-analytics.service';
import { TranslateService } from '@ngx-translate/core';
import { register } from 'swiper/element/bundle';

register();

declare let UXCam: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private onesignalService: OnesignalService,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private translate: TranslateService
  ) {
    this.platform.ready().then(() => {
      /* OneSignal */
      this.onesignalService.init();

      if (this.platform.is('hybrid')) {
        // Inicializa UXCam solo en dispositivos (iOS o Android)
        UXCam.optIntoSchematicRecordings()
        const configuration = {
          userAppKey: 'lv0ncu3bn43prgi',
          enableAutomaticScreenNameTagging:true,
          enableImprovedScreenCapture:true,
        }
        UXCam.startWithConfiguration(configuration);
      }

      setTimeout(() => {
        SplashScreen.hide();
      }, 1000);

      
    })
    let lang = window.navigator.language;
    if(lang.indexOf('es') >= 0 ){
      this.translate.setDefaultLang('es');
    }else if(lang.indexOf('pt') >= 0 ){
      this.translate.setDefaultLang('pt');
    }else if(lang.indexOf('en') >=0 ){
      this.translate.setDefaultLang('en');
    }else{
      this.translate.setDefaultLang('es');
    }

  }

}

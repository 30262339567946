<ion-content>

  <ion-buttons class="close-modal" (click)="onCloseModal()">
    <ion-button fill="clear" color="primary">
      <ion-icon name="close-circle-outline"></ion-icon>
    </ion-button>
  </ion-buttons>

    <swiper-container class="slides" [config]="config"
    zoom="true" pagination-clickable="true">
      <swiper-slide>
        <div class="swiper-zoom-container">
          <img [src]="image">
        </div>
      </swiper-slide>
    </swiper-container>

</ion-content>

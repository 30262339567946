<ion-header>
  <ion-toolbar>
    <ion-title class="ion-text-center">{{ "nav-menu.titulo-menu" | translate}}</ion-title>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-list id="inbox-list">
    <ion-menu-toggle auto-hide="false"
      (click)="onIr('/tabs/nuevo-pedido-listado')">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="add-circle"></ion-icon>
        <ion-label>{{ "nav-menu.txt-nuevo-pedido" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('tabs/home')">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="home"></ion-icon>
        <ion-label>{{ "nav-menu.txt-inicio" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('/tabs/usuario-perfil')">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="person"></ion-icon>
        <ion-label>{{ "tabs.txt-perfil" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="onIr('/tabs/sumasolar-panel')">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="gift"></ion-icon>
        <ion-label>{{ "nav-menu.txt-sumasolar-panel" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
    
    <ion-menu-toggle auto-hide="false"
    (click)="onIr('contacto')">
    <ion-item lines="none" detail="false">
      <ion-icon slot="start" name="chatbox-ellipses"></ion-icon>
      <ion-label>{{ "nav-menu.txt-contacto" | translate}}</ion-label>
    </ion-item>
  </ion-menu-toggle>
    <ion-menu-toggle auto-hide="false" (click)="logout()">
      <ion-item lines="none" detail="false">
        <ion-icon slot="start" name="log-out"></ion-icon>
        <ion-label>{{ "nav-menu.txt-salir" | translate}}</ion-label>
      </ion-item>
    </ion-menu-toggle>
  </ion-list>
  <p class="ion-text-center version">
    <ng-container *ngIf="platform.is('ios')">
      V. {{env.version_ios}}
    </ng-container>
    <ng-container *ngIf="!platform.is('ios')">
      V. {{env.version_android}}
    </ng-container>
  </p>

  <br>
  
  <div class="ion-margin">
    <p style="margin-bottom: 0;"><b>{{ "contacto.txt-horario-atencion" | translate}}:</b></p>
    <p style="margin: 0;">{{ "contacto.txt-lun-vie" | translate}}</p>
    <p style="margin: 0;">{{ "contacto.txt-horario-atencion-horas" | translate}}</p>

    <div class="item-btn">
      <div>
        <p style="margin-bottom: 0;"><b>{{ "contacto.txt-telefono" | translate}}:</b></p>
        <p style="margin: 0;">{{ "contacto.txt-telefono-numero" | translate}}</p>
      </div>
      <div>
        <ion-button fill="clear" (click)="onCall()" class="btn">
          <ion-icon name="call"></ion-icon>
        </ion-button>
      </div>
    </div>
    <div class="item-btn ion-padding-top">
      <div>
        <p style="margin-bottom: 0;"><b>{{ "contacto.txt-correo" | translate}}:</b></p>
        <p style="margin: 0;">{{ "contacto.txt-correo-mail" | translate}}</p>
      </div>
      <div>
        <ion-button fill="clear" (click)="onSendMail()" class="btn">
          <ion-icon name="mail"></ion-icon>
        </ion-button>
      </div>
    </div>
   
  </div>

  
</ion-content>

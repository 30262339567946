import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./paginas/autenticacion/aut-login/aut-login.module').then(
        (m) => m.AutLoginPageModule
      ),
  },
  {
    path: 'aut-reestablecer-pass',
    loadChildren: () =>
      import(
        './paginas/autenticacion/aut-reestablecer-pass/aut-reestablecer-pass.module'
      ).then((m) => m.AutReestablecerPassPageModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./paginas/tabs/tabs.module').then((m) => m.TabsPageModule),
      },
      {
        path: 'com-chats',
        loadChildren: () =>
          import('./paginas/comunicacion/com-chats/com-chats.module').then(
            (m) => m.ComChatsPageModule
          ),
      },
      {
        path: 'com-conversacion',
        loadChildren: () =>
          import(
            './paginas/comunicacion/com-conversacion/com-conversacion.module'
          ).then((m) => m.ComConversacionPageModule),
      },
      {
        path: 'com-envio',
        loadChildren: () =>
          import('./paginas/comunicacion/com-envio/com-envio.module').then(
            (m) => m.ComEnvioPageModule
          ),
      },
      {
        path: 'pedido-detalle',
        loadChildren: () =>
          import('./paginas/pedidos/pedido-detalle/pedido-detalle.module').then(
            (m) => m.PedidoDetallePageModule
          ),
      },
      {
        path: 'nuevo-pedido-detalle',
        loadChildren: () =>
          import(
            './paginas/nuevo-pedido/nuevo-pedido-detalle/nuevo-pedido-detalle.module'
          ).then((m) => m.NuevoPedidoDetallePageModule),
      },
      {
        path: 'sumasolar-panel',
        loadChildren: () =>
          import(
            './paginas/usuarios/sumasolar-panel/sumasolar-panel.module'
          ).then((m) => m.SumasolarPanelPageModule),
      },
      {
        path: 'ayuda-tecnica',
        loadChildren: () =>
          import('./paginas/ayuda/ayuda-tecnica/ayuda-tecnica.module').then(
            (m) => m.AyudaTecnicaPageModule
          ),
      },
      {
        path: 'instalacion-detalle',
        loadChildren: () =>
          import(
            './paginas/instalaciones/instalacion-detalle/instalacion-detalle.module'
          ).then((m) => m.InstalacionDetallePageModule),
      },
      {
        path: 'nuevo-pedido-compra',
        loadChildren: () =>
          import(
            './paginas/nuevo-pedido/nuevo-pedido-compra/nuevo-pedido-compra.module'
          ).then((m) => m.NuevoPedidoCompraPageModule),
      },
      {
        path: 'nuevo-pedido-listado',
        loadChildren: () =>
          import(
            './paginas/nuevo-pedido/nuevo-pedido-listado/nuevo-pedido-listado.module'
          ).then((m) => m.NuevoPedidoListadoPageModule),
      },
      {
        path: 'ayuda-preguntas-frecuentes',
        loadChildren: () =>
          import(
            './paginas/ayuda/ayuda-preguntas-frecuentes/ayuda-preguntas-frecuentes.module'
          ).then((m) => m.AyudaPreguntasFrecuentesPageModule),
      },
      {
        path: 'contacto',
        loadChildren: () => import('./paginas/contacto/contacto.module').then( m => m.ContactoPageModule)
      },
      /* {
        path: 'monitorizacion',
        loadChildren: () =>
          import('./paginas/monitorizacion/home/monitorizacion.module').then(
            (m) => m.MonitorizacionPageModule
          ),
      }, */
      {
        path: 'consumo',
        loadChildren: () => import('./paginas/monitorizacion/consumo/consumo.module').then( m => m.ConsumoPageModule)
      },
      {
        path: 'bateria',
        loadChildren: () => import('./paginas/monitorizacion/bateria/bateria.module').then( m => m.BateriaPageModule)
      },
      {
        path: 'factura',
        loadChildren: () => import('./paginas/monitorizacion/factura/factura.module').then( m => m.FacturaPageModule)
      },
      {
        path: 'energetica',
        loadChildren: () => import('./paginas/monitorizacion/energetica/energetica.module').then( m => m.EnergeticaPageModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./paginas/monitorizacion/general/general.module').then( m => m.GeneralPageModule)
      },
      {
        path: 'espera',
        loadChildren: () => import('./paginas/monitorizacion/espera/espera.module').then( m => m.EsperaPageModule)
      },
      {
        path: 'config-spin',
        loadChildren: () => import('./paginas/spin/config-spin/config-spin.module').then( m => m.ConfigSpinPageModule)
      },
      {
        path: 'spin-inicio',
        loadChildren: () => import('./paginas/spin/spin-inicio/spin-inicio.module').then( m => m.SpinInicioPageModule)
      },
      {
        path: 'nuevo-dispositivo-spin',
        loadChildren: () => import('./paginas/spin/nuevo-dispositivo-spin/nuevo-dispositivo-spin.module').then( m => m.NuevoDispositivoSpinPageModule)
      },
      {
        path: 'detalle-dispositivo-spin',
        loadChildren: () => import('./paginas/spin/detalle-dispositivo-spin/detalle-dispositivo-spin.module').then( m => m.DetalleDispositivoSpinPageModule)
      },
      {
        path: 'listado-dispositivos-spin',
        loadChildren: () => import('./paginas/spin/listado-dispositivos-spin/listado-dispositivos-spin.module').then( m => m.ListadoDispositivosSpinPageModule)
      },
      {
        path: 'conf-dispositivo-spin',
        loadChildren: () => import('./paginas/spin/conf-dispositivo-spin/conf-dispositivo-spin.module').then( m => m.ConfDispositivoSpinPageModule)
      },
      {
        path: 'conf-semsai',
        loadChildren: () => import('./paginas/spin/conf-semsai/conf-semsai.module').then( m => m.ConfSemsaiPageModule)
      },
      {
        path: 'conf-periodo-spin',
        loadChildren: () => import('./paginas/spin/conf-periodo-spin/conf-periodo-spin.module').then( m => m.ConfPeriodoSpinPageModule)
      },
      {
        path: 'spin-listado-notificaciones',
        loadChildren: () => import('./paginas/spin/spin-listado-notificaciones/spin-listado-notificaciones.module').then( m => m.SpinListadoNotificacionesPageModule)
      },
      {
        path: 'usuario-datos-personales',
        loadChildren: () => import('./paginas/usuarios/usuario-datos-personales/usuario-datos-personales.module').then( m => m.UsuarioDatosPersonalesPageModule)
      },
      {
        path: 'cambiar-contrasena',
        loadChildren: () => import('./paginas/usuarios/cambiar-contrasena/cambiar-contrasena.module').then( m => m.CambiarContrasenaPageModule)
      },
      {
        path: 'configuracion-usuario',
        loadChildren: () => import('./paginas/usuarios/configuracion-usuario/configuracion-usuario.module').then( m => m.ConfiguracionUsuarioPageModule)
      },
      {
        path: 'usuario-archivos',
        loadChildren: () => import('./paginas/usuarios/usuario-archivos/usuario-archivos.module').then( m => m.UsuarioArchivosPageModule)
      },
      {
        path: 'usuario-documentacion-general',
        loadChildren: () => import('./paginas/usuarios/usuario-documentacion-general/usuario-documentacion-general.module').then( m => m.UsuarioDocumentacionGeneralPageModule)
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { UsuarioService } from '../singleton/usuario.service';
import { Injectable } from "@angular/core";
import { NavegacionService } from '../navegacion/navegacion.service';
import { AlertasService } from '../alertas/alertas.service';
import OneSignal from 'onesignal-cordova-plugin';
import { Platform } from '@ionic/angular';
import { GeneralObsService } from '../observables/general-obs.service';
//import {NativeAudio} from '@capacitor-community/native-audio'
@Injectable({
	providedIn: "root"
})
export class OnesignalService {
	intervalUserAuth: any;
	intentosInterval: number = 0;

	constructor(
		private usuService: UsuarioService,
		private navegacionService: NavegacionService,
		private alertasService: AlertasService,
    private platform: Platform,
    private generalObsService: GeneralObsService,

	) { }

	init() {
		if (this.platform.is('hybrid')) return;
		(window as any).plugins?.OneSignal?.setAppId("2c3844d3-38e2-433b-aa91-ca2417cc3356");

		(window as any).plugins?.OneSignal?.setNotificationWillShowInForegroundHandler((jsonData) => {
			// do something when notification is received
			let data:any = jsonData?.getNotification()?.additionalData;
			if (data?.redirect  && [].includes(data.redirect)) {
				return;
			}
			/* NativeAudio.play({
				assetId: 'notification_sound',
				time: 0.0,
			}); */
      this.alertasService.presentAlertInfo(jsonData.getNotification().title, jsonData.getNotification().body, () => {
        let dataNotification: any = data;
        dataNotification.refresh = true;
        if (this.usuService?.usu?.id) {
          this.goTo(dataNotification, true);
        }
      })
		});

    (window as any).plugins?.OneSignal?.setNotificationOpenedHandler((jsonData) => {
      let data: any = jsonData?.notification?.additionalData;
			if (data?.redirect) {
				let dataNotification: any = data;
        dataNotification.refresh = true;

        this.intentosInterval = 1;
				this.alertasService.presentLoading();
				this.intervalUserAuth = setInterval(() => {
					this.intentosInterval++;
					if (this.intentosInterval >= 10) {
						this.alertasService.closePresentLoading();
						clearInterval(this.intervalUserAuth)
					};
					if (this.usuService?.usu?.id) {
						clearInterval(this.intervalUserAuth);
						this.alertasService.closePresentLoading();
						this.goTo(dataNotification);
						if (jsonData?.action?.actionId) {
							this.onActions(jsonData?.action?.actionId, dataNotification);
						}
					}
        }, 1000)
			}
		});

		(window as any).plugins?.OneSignal?.promptForPushNotificationsWithUserResponse(function(accepted) {
			console.log("User accepted notifications: " + accepted);
		});

		(window as any).plugins?.OneSignal?.getDeviceState((u) => {
			//if (this.platform.is("mobileweb")) return;
			this.usuService.onesignalPlayerId = u.userId;
		})

  }

  onActions(action, params) {
		switch (action) {
			case "no-estoy-instalando":
				/* let data: any = {};
				data.cliente_id = params.cliente_id;
				data.incidencia_id = params.incidencia_id;
				this.apiService.post("postinstaladornoinstalando", data)
					.subscribe(res => {
					}, err => {
						console.log(err);
					}); */
				break;
		}
	}

	removeExternalUserId() {
		if (this.platform.is("hybrid")) return;
		(window as any).plugins?.OneSignal?.removeExternalUserId();
	}

	setUserId(id) {
		if (this.platform.is("hybrid")) return;
		(window as any).plugins?.OneSignal?.setExternalUserId(String(id));
	}

  goTo(dataNotification, inForeground = false) {
		//if (this.platform.is("mobileweb")) return;
		switch (dataNotification.redirect) {
			case "clientes-lista":
				 this.navegacionService.goTo("clientes-lista", dataNotification);
			break;
      case "pedido-detalle":
        this.alertasService.presentLoading();
        if (inForeground) {
          this.generalObsService.refrescarClientesPub(dataNotification)
        } else {
          this.navegacionService.goTo("tabs/pedidos-listado", dataNotification)
        }
			break;
		}
	}

}

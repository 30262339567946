<div class="tarjeta" *ngIf="type=='image'">
  <ng-container *ngIf="images?.length > 0 && mostrarImg">
    <div *ngFor="let fileMul of images; let i = index">
      <ion-button fill="clear" (click)="removerFiles(i)" color="danger">
        <ion-icon slot="start" name="trash-outline"></ion-icon>
      </ion-button>
      <ion-img [src]="fileMul"></ion-img>
    </div>
  </ng-container>
  <ng-container *ngIf="image && mostrarImg">
    <ion-button fill="clear" (click)="removerFile()" color="danger">
      <ion-icon slot="start" name="trash-outline"></ion-icon>
    </ion-button>
    <ion-img *ngIf="image.length>0" [src]="image"></ion-img>
  </ng-container>
  <p>{{ text ?? ("generales.txt-select-file" | translate) }} <span *ngIf="nImage > 1">{{nImage}} fotos</span></p>
  <div class="btns">
    <ion-button fill="clear" (click)="onOpenFolder()" *ngIf="sourceImg == CameraSource.Prompt || sourceImg == CameraSource.Photos">
      <ion-icon name="attach"></ion-icon>
    </ion-button>
    <ion-button fill="clear" (click)="onOpenCamera()" *ngIf="sourceImg == CameraSource.Prompt || sourceImg == CameraSource.Camera">
      <ion-icon name="camera"></ion-icon>
    </ion-button>
  </div>
</div>

<div class="tarjeta" *ngIf="type=='pdf'">
  <ng-container *ngIf="images.length > 0 && mostrarImg">
    <div *ngFor="let file of images; let i = index">
      <ion-button fill="clear" (click)="removerFiles(i, btnUploadPdf)" color="danger">
        <ion-icon name="trash-outline"></ion-icon>
      </ion-button>
      <div class="document">
        <ion-icon name="document-outline"></ion-icon>
        <p class="ion-text-center" *ngIf="file?.filename">{{file.filename}}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="image && mostrarImg">
    <ion-button fill="clear" (click)="removerFile(btnUploadPdf)" color="danger">
      <ion-icon name="trash-outline"></ion-icon>
    </ion-button>
    <div class="document">
      <ion-icon name="document-outline"></ion-icon>
      <p class="ion-text-center" *ngIf="image?.filename">{{image.filename}}</p>
    </div>
  </ng-container>
  <p>{{ text ?? ("generales.txt-select-file" | translate) }} <span *ngIf="nImage > 1">Max. {{nImage}}</span></p>
  <div class="btns">
    <ion-button fill="clear" (click)="btnUploadPdf.click()">
      <ion-icon name="attach"></ion-icon>
    </ion-button>
    <input
      class="ion-hide"
      #btnUploadPdf
      type="file"
      (change)="changeFilePdf($event, btnUploadPdf)"
      accept="application/pdf"
      [multiple]="(nImage > 1) ? true: false"
    />
  </div>
</div>

<div class="tarjeta" *ngIf="type=='any'">
  <ng-container *ngIf="images.length > 0 && mostrarImg">
    <div *ngFor="let file of images; let i = index">
      <ion-button fill="clear" (click)="removerFiles(i, btnUploadPdf)" color="danger">
        <ion-icon name="trash-outline"></ion-icon>
      </ion-button>
      <div class="document">
        <ion-icon name="document-outline"></ion-icon>
        <p class="ion-text-center" *ngIf="file?.filename">{{file.filename}}</p>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="image && mostrarImg">
    <ion-button fill="clear" (click)="removerFile(btnUploadPdf)" color="danger">
      <ion-icon name="trash-outline"></ion-icon>
    </ion-button>
    <div class="document">
      <ion-icon name="document-outline"></ion-icon>
      <p class="ion-text-center" *ngIf="image?.filename">{{image.filename}}</p>
    </div>
  </ng-container>
  <p>{{ text ?? ("generales.txt-select-file" | translate) }} <span *ngIf="nImage > 1">Max. {{nImage}}</span></p>
  <div class="btns">
    <ion-button fill="clear" (click)="btnUploadPdf.click()">
      <ion-icon name="attach"></ion-icon>
    </ion-button>
    <input
      class="ion-hide"
      #btnUploadPdf
      type="file"
      (change)="changeFilePdf($event, btnUploadPdf)"
      accept="*/*"
      [multiple]="(nImage > 1) ? true: false"
    />
  </div>
</div>
import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { initializeApp} from 'firebase/app';
// Init for the web


// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyD2vygwgnki1SJB79GXNgkrM_61F5l3_vY",
  authDomain: "soty-solar-ga4.firebaseapp.com",
  projectId: "soty-solar-ga4",
  storageBucket: "soty-solar-ga4.appspot.com",
  messagingSenderId: "411064393043",
  appId: "1:411064393043:web:cffe49538ef9909437d5a6",
  measurementId: "G-MMSGG9BEVB"
};

@Injectable({
  providedIn: 'root'
})
export class FirebaseAnalyticsService {

  constructor(
  ) { }

  init() {
    initializeApp(firebaseConfig);
  }

  setUserProperty(id, email) {
    FirebaseAnalytics.setUserId({
      userId: id,
    });

    FirebaseAnalytics.setUserProperty({
      "key": "id",
      "value": id,
    });
    FirebaseAnalytics.setUserProperty({
      "key": "email",
      "value": email,
    });
  }

  logEvent(name: string, params: any) {
    FirebaseAnalytics.logEvent({
      name: name,
      params: params
    }).then((res: any) => {
      //console.log(res)
    })
    .catch((error: any) => console.error(error));

  }

  setCurrentScreen(screenName) {
    FirebaseAnalytics.setCurrentScreen({
      screenName: screenName,
      screenClassOverride: screenName+"Screen",
    })
    .then((res: any) => {
      //console.log(res)
    })
    .catch((error: any) => console.error(error));
  }

}

<ion-content class="ion-padding" [scrollX]="false" [scrollY]="false">

  <div class="img">
    <img src="assets/img/instalador_contento.svg" alt="Mi Imagen" style="width: 100%;">
  </div>

  <div class="soperte-titulo">
    <p class="titulo">{{ "contacto.txt-gracias" | translate}}</p>
  </div>

  <div class="texto-contacto">
    <p>{{ "contacto.txt-nuestro-equipo-contacto" | translate}}</p>
  </div>

  <div class="btn-enviar">
    <ion-button  color="success">{{ "contacto.txt-enviado" | translate}}</ion-button>
  </div>

</ion-content>
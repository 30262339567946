import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CameraResultType, CameraSource } from '@capacitor/camera';
import { TranslateService } from '@ngx-translate/core';
import { AlertasService } from 'src/app/servicios/alertas/alertas.service';
import { FileService } from 'src/app/servicios/file/file.service';
import { PhotoService } from 'src/app/servicios/file/photo.service';

@Component({
  selector: 'app-form-agregar-imagen',
  templateUrl: './form-agregar-imagen.component.html',
  styleUrls: ['./form-agregar-imagen.component.scss'],
  standalone: false,
})
export class FormAgregarImagenComponent implements OnInit {


  @Input() images: Array<any> = [];
  @Input() image: any;
  @Input() type: string = "image";
  @Input() nImage: number = 1;
  @Input() mostrarImg: boolean = true;
  @Input() sourceImg: CameraSource = CameraSource.Prompt;
  @Input() resultType: CameraResultType = null;
  @Input() text: string;
  
  
  public CameraSource = CameraSource;

  @Output() onImagen = new EventEmitter();

  constructor(
    private fileService: FileService,
    private photoService: PhotoService,
    private translate: TranslateService,
    private alertasService: AlertasService,
  ) { }

  ngOnInit() { 
    if (!Array.isArray(this.images)) this.images = [];
  }
  
  onOpenFolder() {
    if (!this.isValidMaxFiles()) return;
    if (this.nImage > 1) {
      let nI = this.nImage - this.images.length;
      this.photoService.multiplePicture(nI).then((files) => {
        if (files.length > 0) {
          this.images = [...this.images, ...files];
          this.onImagen.emit(this.images);
        }
      })
    } else {
      this.photoService.takePicture(CameraSource.Photos, this.resultType).then((file) => {
        this.image = file;
        this.onImagen.emit(file);
      })
    }
  }
  
  onOpenCamera() {
    if (!this.isValidMaxFiles()) return;
    this.photoService.takePicture(CameraSource.Camera, this.resultType).then((file) => {
      if (!file) return;
      if (this.nImage > 1) { 
        this.images.push(file);
        this.onImagen.emit(this.images);
      } else {
        this.image = file;
        this.onImagen.emit(file);
      }
    })
  }

  changeFilePdf(e, element) {
    let files = e.target.files;
    this.fileService.normalizeFiles(files)
    .then(async files => {
      if (this.nImage > 1) {
        let nI = (this.images.length + files.length);
        if (nI <= this.nImage) {
          this.images = [...this.images, ...files];
          this.onImagen.emit(files);
        } else {
          this.alertasService.presentToast(this.translate.instant("photo-service.error-limit-img"),3000);
        }
      } else {
        this.image = files[0];
        this.onImagen.emit(files[0]);
      }
    });
  }

  isValidMaxFiles() {
    if (this.nImage > 1 && this.images.length >= this.nImage) {
      if (this.nImage > 1) {
        this.alertasService.presentToast(this.translate.instant("photo-service.error-limit-imgs",{limit: this.nImage}),3000);
      } else {
        this.alertasService.presentToast(this.translate.instant("photo-service.error-limit-img"),3000);
      }
      return false;
    }
    return true;
  }

  removerFiles(i, element = null) {
    this.images.splice(i, 1)
    element.value = null;
    this.onImagen.emit(this.images);
  }
  
  removerFile(element = null) {
    this.image = null;
    element.value = null;
    this.onImagen.emit(this.image);
  }


}

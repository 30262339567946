<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" menuId="first" side="end">
        <app-nav-menu></app-nav-menu>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
  <div class="custom-loading hide">
    <ion-spinner name="bubbles" color="secondary"></ion-spinner>
    <p class="text"></p>
  </div>
</ion-app>

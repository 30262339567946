import { Injectable } from '@angular/core';
import { EstadosClientes } from 'src/app/enums/estados-clientes.enum';
import { Cliente } from 'src/app/modelos/cliente.model';

@Injectable({
  providedIn: 'root'
})

export class ClienteService{
  public clientes: Array<Cliente> = [];
  
  constructor(
  ) {
  }

  getNClientesCancelados() {
    return this.clientes?.filter(item => item.estadoslug === EstadosClientes.cancelado).length;
  }


}

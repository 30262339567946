// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tarjeta {
  background: #F3F3F3;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  display: block;
  margin: 10px 0px;
}
.tarjeta p {
  font-size: 14px;
  color: #92949C;
}
.tarjeta .btns {
  text-align: center !important;
  margin-bottom: 20px !important;
  margin: auto;
}
.tarjeta .btns ion-button {
  background: #FFFFFF;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 27px;
  font-size: 15px;
  width: 56px;
  height: 56px;
  margin: 5px;
  display: inline-block;
}
.tarjeta .btns ion-button ion-icon {
  color: #222428;
}
.tarjeta .btns ion-button.active {
  background: #222428;
}
.tarjeta .btns ion-button.active ion-icon {
  color: #FFFFFF;
}
.tarjeta ion-img {
  margin-bottom: 4px;
  width: auto;
  height: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/componentes/formularios/form-agregar-imagen/form-agregar-imagen.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,cAAA;EACA,gBAAA;AACJ;AAAI;EACI,eAAA;EACA,cAAA;AAER;AAAI;EACI,6BAAA;EACA,8BAAA;EACA,YAAA;AAER;AADQ;EACI,mBAAA;EACA,2CAAA;EACA,mBAAA;EACA,eAAA;EACA,WAAA;EACA,YAAA;EACA,WAAA;EACA,qBAAA;AAGZ;AAFY;EACI,cAAA;AAIhB;AAFY;EACI,mBAAA;AAIhB;AAHgB;EACI,cAAA;AAKpB;AAAI;EACI,kBAAA;EACA,WAAA;EACA,aAAA;AAER","sourcesContent":[".tarjeta{\n    background: #F3F3F3;\n    text-align: center;\n    padding: 5px;\n    border-radius: 6px;\n    display: block;\n    margin: 10px 0px;\n    p{\n        font-size: 14px;\n        color:#92949C;\n    }\n    .btns{\n        text-align: center !important;\n        margin-bottom: 20px !important;\n        margin: auto;\n        ion-button{\n            background: #FFFFFF;\n            box-shadow: 0px 1px 4px rgb(0 0 0 / 25%);\n            border-radius: 27px;\n            font-size: 15px;\n            width: 56px;\n            height: 56px;\n            margin: 5px;\n            display: inline-block;\n            ion-icon{\n                color:#222428;\n            }\n            &.active{\n                background: #222428;\n                ion-icon{\n                    color:#FFFFFF;\n                }\n            }\n        }\n    }\n    ion-img{\n        margin-bottom: 4px;\n        width: auto;\n        height: 100px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

<ion-header class="header" mode="md">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button class="btn-back" *ngIf="!volver" fill="clear">
      </ion-button>
      <ion-button class="btn-back" *ngIf="volver" fill="clear" (click)="onVolver()">
        <ion-icon name="chevron-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-img 
      [src]="isDarkMode ? '/assets/img/sotysolar_blanco.svg' : '/assets/img/sotysolar.svg'"
      class="logo-soty">
    </ion-img>
    <ion-buttons slot="end">
      <ion-button *ngIf="menu || volver" fill="clear" (click)="onMenu()">
        <ion-icon name="menu-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <ion-item *ngIf="tituloSel.length > 0" lines="none">
    <ion-select [value]="tituloSel[0].valor" [interfaceOptions]="customPopoverOptions" interface="popover" mode="ios" (ionChange)="onTituloSel($event)">
      <ion-select-option [value]="titulo.valor" *ngFor="let titulo of tituloSel">{{titulo.texto}}</ion-select-option>
    </ion-select>
  </ion-item>
  <ion-segment (ionChange)="onSegmSel($event)" mode="ios" [value]="segmSel" *ngIf="segmentaciones.length > 0">
    <ion-segment-button *ngFor="let seg of segmentaciones" [value]="seg.slug">
      <ion-label>{{seg.titulo}}</ion-label>
    </ion-segment-button>
  </ion-segment>

</ion-header>
